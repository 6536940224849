(function ($) {

	$(".banner").slick({
		dots: true,
		infinite: true,
		arrows: false,
	});
 
	$(".sponsor").slick({
		dots: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 2000,
		slidesToShow: 3,
		slidesToScroll: 3,
		arrows: false,
		responsive: [
			{
				breakpoint: 890,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	$(window).scroll(function () {

		console.log($(window).scrollTop());

		if ($(window).scrollTop() > 40) {
			$(".site-content").addClass("active");
			$("header").addClass("active");

			$("#banner").slideUp("50");
			$("#notice-timer").slideUp("1600"); 
		} else {
			$(".site-content").addClass("active");
			$("header").removeClass("active");

			$("#banner").slideDown("50");
			$("#notice-timer").slideDown();
		}
	});

	if ($("#notice-timer").length) {
		$("#banner").addClass("active");
		$(".site-content").addClass("active");
	}
})(jQuery);
